import React from 'react'
import { graphql } from 'gatsby'

import { Layout } from '../layout'
import { Head } from '../components/head'

class Privacy extends React.Component {
    render() {
        const { data } = this.props
        const siteTitle = data.site.siteMetadata.title

        return (
            <Layout location={this.props.location} title={siteTitle}>
                <Head title="Privacy" />
                <h1>Privacy</h1>
                <p>GCNotFound does not collect your data nor is it storing it. 😎</p>
                <p>Please be aware that appstores and other delivery mediums might have their own privaciy policies and they might have a different approach...</p>
            </Layout>
        )
    }
}

export default Privacy

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
